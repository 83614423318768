<template>
  <div>
    <headerSection></headerSection>

    <div id="main__content" class="">
      <div class="x-main-container">
        <div class="x-main-content">
            <div class="x-promotion-index" :style="{ 'background-image': `url(${require('../assets/images/Shiba-BG.jpg')})`}">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="x-page-title-component -long-support">
                                <div class="-inner-wrapper">
                                    <h1 class="-title">โปรโมชัน ที่ Cow888 มีมากกว่า แตกง่ายกว่า</h1>
                                </div>
                            </div>
                            <p class="-description">ใช้ได้หมดทุกแบร์น</p>
                            <p class="-description">ฝาก ไม่รับโบนัส, ถอนได้ทันทีไม่มีเงื่อนไข</p>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row -row-wrapper x-divine-gutter">
                        <div class="col-12 col-md-6 mt-3 -col-wrapper x-divine-gutter animated fadeInUp" style="padding-top: 10px;" data-animatable="fadeInUp" data-delay="100" v-for="(item,index) in banners" :key="index" @click="showPromotion(item)">
                            <a href="#0" class="x-promotion-list-item ">
                            <picture>
                                <source type="image/png" :srcset="item.image">
                                <img class="-cover-img img-fluid" :alt="item.name" style="border-radius:0px;" width="200" height="200" :src="item.image">
                            </picture>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="container mt-4">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="-sub-title"><a href="https://cow888.co">Cow888</a>&nbsp;คาสิโนชั้นนำ รวมแบร์นดัง มีทุกค่าย รับง่าย ถอนไว</h2>
                            <p class="-description">
                                ศูนย์รวมแบร์นที่ดีทีสุดและเกมส์คาสิโนทุกเกมส์ไว้ที่นี่ที่เดียว อาทิ UFA AMB EDMBET และ Betflix เฉพาะครอบครัว Cow888 เท่านั้น
                            </p>
                        </div>
                    </div>
                </div>
            </div>


          <footerSection></footerSection>
        </div>
      </div>

      <div class="x-right-sidebar-container"></div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Promotion',
  components: {
    headerSection: () => import("./Header.vue"),
    footerSection: () => import("./Footer.vue")
  },
  mounted () {
    this.$store.dispatch('user/getBanners').catch(err => { console.error(err) });
    this.scrollToTop()
  },
  computed: {
  	banners(){
        return this.$store.state.user.banners;
    },
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    showPromotion(item){
      if(item.detail){
        this.$store.commit('user/SET_PROMOTION', item)
      }
    }
  }
}
</script>